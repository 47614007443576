var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "govuk-tabs" },
    [
      _c("h2", { staticClass: "govuk-tabs__title" }, [
        _vm._v(_vm._s(_vm.title))
      ]),
      _c(
        "ul",
        {
          staticClass: "govuk-tabs__list",
          class: { "govuk-tabs__list--bar": _vm.bar }
        },
        _vm._l(_vm.tabs, function(tab, index) {
          return _c(
            "li",
            { key: index, staticClass: "govuk-tabs__list-item" },
            [
              _vm.noRouter
                ? _c("a", {
                    staticClass: "govuk-tabs__tab",
                    class: {
                      "govuk-tabs__tab--active": tab.active,
                      "govuk-tabs__tab--bar": _vm.bar
                    },
                    attrs: { href: "javascript:;" },
                    domProps: { textContent: _vm._s(tab.heading) },
                    on: {
                      click: function($event) {
                        return _vm.onTabClick(tab, index)
                      }
                    }
                  })
                : _c("router-link", {
                    staticClass: "govuk-tabs__tab",
                    class: { "govuk-tabs__tab--bar": _vm.bar },
                    attrs: {
                      to: tab.to,
                      "active-class": "govuk-tabs__tab--active",
                      exact: ""
                    },
                    domProps: { textContent: _vm._s(tab.heading) }
                  })
            ],
            1
          )
        }),
        0
      ),
      _vm.panel
        ? _c("gov-tab-panel", [_vm._t("default")], 2)
        : _vm._t("default")
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }